import React from "react";
import SimpleReactValidator from "simple-react-validator";
import { env } from "../functional/global-import";
import moment from 'moment';
import {
  ProfileIMG,
  // bookNoImg 
} from '../functional/global-image-import';
import { Loader, Dimmer } from 'semantic-ui-react';
// import { Label } from "semantic-ui-react";
// This function is used to handle common onchange in all the forms in the application.

const onHandleChange = (e, { name, value, type, checked, data }, obj) => {
  const path = data.split(".");
  const depth = path.length;
  const state = { ...obj };
  let ref = state;
  for (let i = 0; i < depth; i += 1) {
    if (i === depth - 1) {
      if (
        type === "text" || type === "radio" || type === "password" || type === "select" ||
        type === "textarea" || type === "multiple-select" || type === "phoneNumber" || type === "number") {
        ref[path[i]] = value;
      } else if (type === "select-int") {
        ref[path[i]] = parseInt(value);
      } else if (type === "checkbox") {
        ref[path[i]] = checked;
      }
    } else {
      ref = ref[path[i]];
    }
  }
  return state;
};

// This function is used to handle common form submit in all the forms in the application.
const onHandleFormSubmit = (e, simpleValidator, forceUpdate) => {
  e.preventDefault();
  if (simpleValidator.current.allValid() === false) {
    simpleValidator.current.showMessages();
    forceUpdate(true);
    return false;
  } else {
    return true;
  }
};

const initializeSimpleValidator = () => {
  return new SimpleReactValidator({
    // element: (message) => (
    //   <Label basic color="red" pointing="above">
    //     {message}
    //   </Label>
    // ),
    validators: {
      // For Custom error Message in confirm Passcode field
      // validConfirmPasscode: {
      //   message: "The 'Passcode' and 'Confirm Passcode' doesn't match'",
      //   rule: (val, params, validator) => {
      //     if (params[0] !== "undefined" && params[0].length > 1) {
      //       return val === params[0];
      //     }
      //   },
      //   required: true, // optional
      // },

      // For Custom error message in confirm Password field
      validConfirmPassword: {
        message: "The Password and Confirm Password doesn't match.",
        rule: (val, params, validator) => {
          if (params[0] !== "undefined" && params[0].length > 1) {
            return val === params[0];
          }
        },
        required: true, // optional
      },
      // validRoles: {
      //   message: "Must be Select One Roles",
      //   rule: (val, params, validator) => {
      //     if (params[0].length > 1) {
      //       return val === params[0];
      //     }
      //   },
      //   required: true,
      // },
    },
  });
};

const concatenateImageWithAPIUrl = (Image) => {
  if (Image === null) {
    return `${ProfileIMG}`;
  }
  else {
    return `${env.IMG_URL}${Image}`
    // return `${env.API_URL.replace("/api", "")}${Image}`;
  }
};

const getFormData = (data) => {
                                   
  var formData = new FormData();
  Object.entries(data).map(function ([key, val]) {

    if (typeof val !== "undefined") {
      if ((key === "file" || key === "pdfFile") && val !== null && (data[key]?.length > 0)) {
        let file = val[0].file === undefined ? val[0] : val[0].file;
        formData.append(key, file);
      }
      else if (key === "ids" || key === "grades" || key === "standard" || key === "comprehensionStrategies" || key === "valuesTag" || key === "literaryElements") {

        var array = val;
        for (var j = 0; j < array.length; j++) {
          formData.append(key, array[j]);
        }
      }
      else if (key === "images" || key === "bookCoverImage") {
        var arr = val;
        for (var i = 0; i < arr.length; i++) {
          let file = val[i].file === undefined ? val[i] : val[i].file;
          formData.append(key, file);
        }
      }
      else {
        formData.append(key, val);
        console.log(key, val);
      }
    }
  });
  return formData;
};
const formattedDate = (date) => {

  return moment(date).format("MMM DD, YYYY");
}
const saveFormattedDate = (date) => {
  return moment(date).format("YYYY-MM-DD");
}
const getSingleValue = (globalCodeData, categoryType, codeName) => {

  const singlevalue = globalCodeData && globalCodeData.filter(code => code.categoryName === categoryType && code.codeName === codeName);
  return singlevalue[0].categoryId;
}
const getGlobalCodeDetails = (globalCodeData, categoryType, codeName) => {
  //                                     
  const singlevalue = globalCodeData.filter((code) => {
    return code.categoryName === categoryType && code.codeName.trim() === codeName
  });
  return singlevalue[0];
}

const truncateText = (truncText, value) => {
  let newTruncateText = truncText.substring(0, value) + '...'
  return newTruncateText;
}
const contactText = (truncText, value) => {
  let newTruncateText = truncText.substring(0, value) + ''
  return newTruncateText;
}
const showLoader = () => {
  return (
    <Dimmer active inverted>
      <Loader />
    </Dimmer>
  )
}

const dropDownDeleteMultiValue = (optionsArray, optionSavedArray) => {
                                                                          
  let newArray = optionsArray.filter((item) => {
    return item.value !== null
  })

  let hwe1 = optionSavedArray.filter((item) => {

    let hwr21 = newArray.filter((item1) => {
      return item1.value === item.GradeId
    })
    if (hwr21.length === 0) {
      return item
    }

  })

  let isDeletedObj = hwe1.map((single1) => {
    return { text: single1.GradeName, value: single1.GradeId }
  })


  let newArraycreated = [...optionsArray, ...isDeletedObj]
  return { newArraycreated: newArraycreated, isDeleted: isDeletedObj.length > 0 }
}

const dropDownDeleteMultiValueBook = (optionsArray, optionSavedArray) => {
                                                                          
  let newArray = optionsArray.filter((item) => {
    return item.value !== null
  })

  let hwe1 = optionSavedArray.filter((item) => {

    let hwr21 = newArray.filter((item1) => {
      return item1.value === item.BookId
    })
    if (hwr21.length === 0) {
      return item
    }

  })

  let isDeletedObj = hwe1.map((single1) => {
    return { text: single1.BookName, value: single1.BookId }
  })


  let newArraycreated = [...optionsArray, ...isDeletedObj]
  return { newArraycreated: newArraycreated, isDeleted: isDeletedObj.length > 0 }


}

const dropDownDeleteStandardMultiValue = (optionsArray, optionSavedArray) => {

  let newArray = optionsArray.filter((item) => {
    return item.value !== null
  })

  let hwe1 = optionSavedArray.filter((item) => {

    let hwr21 = newArray.filter((item1) => {
      return item1.value === item.GradeId
    })
    if (hwr21.length === 0) {
      return item
    }

  })

  let isDeletedObj = hwe1.map((single1) => {
    return { text: single1.StandardName, value: single1.StandardId }
  })


  let newArraycreated = [...optionsArray, ...isDeletedObj]
  return { newArraycreated: newArraycreated, isDeleted: isDeletedObj.length > 0 }
}

const dropdownDeletedValue = (optionsArray, optionValue, optionText) => {
  let isAlreadyExist = false


  optionsArray.map((singleObj) => {
    if (singleObj.value === optionValue) {
      isAlreadyExist = true
    }
    return singleObj
  })
  !isAlreadyExist && optionsArray.push({ text: optionText, value: optionValue });
  return optionsArray;
}

const isJsonString = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}
const isNumberInteger = (value) => {
  if (value !== null && value !== undefined) {
    let a = value
    if (typeof value === "number") {
      a = JSON.stringify(value)
    }
    let b = a.split(".");
    if (b[0].length === 0) {
      return "$ " + (b[1] !== undefined ? (b[1].length === 1 ? "0" + value + "0" : "0" + value) : "0" + value + ".00");
    } else {
      return "$ " + (b[1] !== undefined ? (b[1].length === 1 ? value + "0" : value) : value + ".00");
    }
  }
  else {
    return "--";
  }
}
const concatenateImageAPIUrl = (Image) => {

  if (Image === null) {
    return `${null}`
  }
  else {
    return `${env.IMG_URL}${Image}`
    // return `${env.API_URL.replace("/api", "")}${Image}` 
  }
};

const onCheckScreenAllowed = (roleAndPermission, screenRoute) => {

  let moduleName = roleAndPermission && roleAndPermission.length > 0 && roleAndPermission?.filter((item) => {
    return item.Access === 1 && item.permission
  });
  let allowedScreens = false
  moduleName && moduleName.length > 0 && moduleName?.map((item) => {
    item.permission.map((item2) => {
      if (item2.Access && item2.ScreenName?.toLowerCase() === `${screenRoute}`) {
        allowedScreens = true
      }
      return item2
    })
    return item
  })
  return allowedScreens
}


const onCheckScreenActions = (userRole, roleAndPermission, screenName = null) => {
 
  if (userRole === "SubAdmin") {
    const oldUrlScreenNames = window.location.href.split("/");
    const oldUrlScreenName = screenName === null ? oldUrlScreenNames[oldUrlScreenNames.length - 1] : screenName
    let moduleName = roleAndPermission && roleAndPermission.length > 0 && roleAndPermission?.filter((item) => {
      return item.Access === 1 && item.permission
    });
    let currentScreenActions = []
    let addAction = false
    let editAction = false
    let deleteAction = false
    moduleName && moduleName.length > 0 && moduleName?.map((item) => {
      item.permission.map((item2) => {
        if (item2.Access && item2.ScreenName?.toLowerCase() === `${oldUrlScreenName}`) {
          currentScreenActions = item2.action
        }
        return item2
      })
      return item
    })
    currentScreenActions.map((SingleActionObj) => {
      if (SingleActionObj.Access === 1 || SingleActionObj.Access === true) {
        if (SingleActionObj.ActionName.toLowerCase() === "add") {
          addAction = true
        }
        else if (SingleActionObj.ActionName.toLowerCase() === "edit") {
          editAction = true
        }
        else if (SingleActionObj.ActionName.toLowerCase() === "delete") {
          deleteAction = true
        }
      }
      return SingleActionObj
    })

    let actionObj = { addAction, editAction, deleteAction }
    return actionObj
  }
  else {
    let actionObj = { addAction: true, editAction: true, deleteAction: true }
    return actionObj
  }
}

export const commonFunctions = {
  onHandleChange, onCheckScreenActions, onCheckScreenAllowed,
  onHandleFormSubmit,
  initializeSimpleValidator,
  concatenateImageWithAPIUrl,
  getFormData,
  formattedDate,
  saveFormattedDate,
  getSingleValue,
  getGlobalCodeDetails,
  truncateText,
  concatenateImageAPIUrl,
  //onHandleSubmit,
  isNumberInteger,
  showLoader,
  isJsonString,
  contactText,
  dropdownDeletedValue,
  dropDownDeleteMultiValue,
  dropDownDeleteStandardMultiValue
};